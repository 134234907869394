<script>
import axios from 'axios';
import { mapGetters } from 'vuex';
import { i18n } from "vue-lang-router";

export default {
    name: 'interview',

    data() {
        return {
            name: '',
            email: '',
        };
    },

    created() {
        document.title = "Intervju med Tempus Bildbyrås ägare";
    },

    computed: {
        ...mapGetters(['isLoading']),

    },

    methods: {
        send() {
            if (this.name == '' || this.email == '') {
                alert('Please complete all fields');
                return;
            }
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.email)) {
                alert('Email field must have email form');
                return;
            }
            axios.post(`${process.env.VUE_APP_BACKEND_URL}/api/newsletter/save`,
                { name: this.name, email: this.email }).then(() => {
                    this.name = "";
                    this.email = "";
                    alert('Saved');
                });
        },
    },

    components: {},
};
</script>

<template>
    <div class="illustration-info-container">
        <div class="illustration-info">
            <div class="content">
                <div class="header p-4">
                    <h1 class="text-3xl">Intervju med bildbyråns ägare</h1>
                </div>
                <br>
                <div class="content-paragraph">
                    <p>I denna intervju berättar Tempus Bildbyrås ägare Alexander Christoffer Svensson om bildbyrån samt om sin relation till historia och kulturarv.</p>
                </div>
                <div class="content-paragraph split-content">
                    <p class="leftSide">
                        <b>Hur kom det sig att du startade bildbyrån?</b>
                        <br/><br/>
                        Jag hade arbetat några år som förlagsredaktör med att producera böcker inom historia och jag märkte vilket behov det fanns för äldre bilder och hur svårt det ibland kunde vara att hitta lämpliga bilder. Jag började därför göra anteckningar om intressanta bilder i olika antika böcker, men efter att ha hållit på så ett tag insåg jag att jag ändå inte hade någon överblick över materialet. Därför bestämde jag mig för att skapa något mer systematiskt – och inte bara för mig, utan för alla med liknande behov. Bildbyråns databas är organiserad på det sätt som jag själv som bildredaktör anser är mest ändamålsenligt.
                        
                    </p>
                     <div class="content-paragraph borderedImage">
                    <img src="@/assets/svea.jpg" style="height: fit-content;" />
                    </div>
                </div>

                <div class="content-paragraph">
                        <p>
                            
                            <b>Hur hittar du bilderna till bildbyrån?</b>
                            <br/><br/>
                            Alla bilderna i bildbyrån är hämtade ur böcker och tidningar, framför allt från 1800-talet. Det är bildbyråns specialitet. Det handlar alltså om att känna till dessa intressanta böcker och tidningar och exakt veta vad man letar efter hos antikvariaten och internetförsäljare.
                            <br/><br/>
                            Särskilt tidningarna innehåller ett mycket intressant dokumentärt bildmaterial som illustrerar aktualiteter löpande. Dessa tidningar gavs ut en gång i veckan eller en gång i månaden och man hittar dem oftast som inbundna hel- eller halvårsvolymer. Jag kan tillägga att det inte är några pocketböcker det handlar om. Jag har kånkat många tunga kassar med böcker.
                            <br/><br/>
                            <b>Hur skulle du beskriva din relation till historia?</b>
                            <br/><br/>
                            Jag har haft ett brinnande intresse för historia i alla dess former ända sedan jag lärde mig läsa. De historiska bilderna har alltid spelat en viktig roll för mig. Faktum är att det var en plansch med ett grupporträtt av alla Sveriges kungar sedan Gustav Vasa som allra först väckte mitt intresse för historia. Min farmor hade Alf Henriksons <i>Svensk historia</i> i två band och jag minns särskilt hur jag som nioåring med stor fascination satt i fåtöljen hos henne och läste om järnålderns kungar av Ynglingaätten.
                            <br/><br/>
                            Det började alltså med att jag intresserade mig för kungar, men snart hade min fascination för det förflutna omfamnat även andra aspekter av mänsklig verksamhet. Barndomens somrar i Södermanlands bergslag skapade exempelvis ett intresse för både järnhanteringens och gruvarbetarnas historia. Vi har historia överallt runt omkring oss – om vi vill se den.
                        </p>
                         
                    </div>

                    <div class="content-paragraph borderedImage showMobile">
                            <img src="@/assets/library.jpg" style="height: fit-content;" />
                            <p>Några hyllor i Tempus Bildbyrås bibliotek. På bilden ses bland annat inbundna volymer av tidningarna <i>Die Gartenlaube</i>, <i>L’illustration</i>, <i>Illustrirte Zeitung</i> och <i>The illustrated London news</i></p>
                        </div>
                <div class="content-paragraph split-content">
                    <p class="leftSide">
                        <b>En del säger att de inte är intresserade av historia ...</b>
                        <br/><br/>
                        Ja, de säger det, men det stämmer inte. Varje tänkande person har sitt särskilda historieintresse, men en del kanske inte inser det. Många tror nog att historia alltid handlar om krig och politik och att det måste vara tråkigt, men det är en missuppfattning. Visst är fältslag och politiska beslut viktiga och självklara delar av historien, men historia är så mycket mer än så! De senaste årens uppsving för populärhistoria har också visat att historia kan vara fängslande och engagerande.
                        
                    </p>
                     <div class="content-paragraph borderedImage hideMobile">
                        <img src="@/assets/library.jpg" style="height: fit-content;" />
                        <p>Några hyllor i Tempus Bildbyrås bibliotek. På bilden ses bland annat inbundna volymer av tidningarna <i>Die Gartenlaube</i>, <i>L’illustration</i>, <i>Illustrirte Zeitung</i> och <i>The illustrated London news</i></p>
                    </div>
                </div>

                <div class="content-paragraph">
                        <p >
                            
                            Även den som säger sig vara ointresserad av historia kan visa sig vara intresserad till exempel av hur en känd plats såg ut förr i tiden, hur jordbruk bedrevs före mekaniseringen eller hur man firade jul innan televisionen satte sin prägel på högtiden. Och vem kan undgå att imponeras av äldre tiders arkitektur? Det och mycket annat är också historia!
                            <br/><br/>
                            Historieforskning och populär historieskrivning har också under senare decennier fått ett mycket bredare fokus, så att även vanliga människor får sin historia skildrad på ett helt annat sätt än förr. På senare år har många börjat intressera sig för det förflutna genom släktforskning och de skapar på det sättet en personlig relation till historien. Många intresserar sig också för sin egen hembygds historia, vare sig det gäller 1950-talet eller 1600-talet. Själv är jag mycket fascinerad av att vandra omkring i mina omgivningar och se spåren av tidigare generationers arbete. Hur anspråkslösa deras verk än må te sig i ett större sammanhang så utgjorde de en byggsten i att bygga det land vi ärvde.
                        </p>
                        
                    </div>
                    <div class="content-paragraph borderedImage showMobile">
                                <img  src="@/assets/skraeddaraasen.jpg" style="height: 100%;" />
                                <p>Torpet Skräddaråsen i Huddinge socken var bebott åren 1760–1817. Nu tvåhundra år senare återstår bara några mossbevuxna husgrunder.</p>
                            </div>
                <div class="content-paragraph split-content">
                    <p class="leftSide">
                        
                        <b>Så det finns många slag av historia?</b>
                  <br/><br/>     
                        Ja, det skulle jag säga. Det handlar i grund och botten om att vi alla är intresserade av olika saker – i vår samtid. Det är naturligt att det även präglar vårt intresse för det förflutna. Har man inget intresse av dagsaktuell politik är det knappast troligt att man intresserar sig för politisk historia. Om man däremot intresserar sig för sjöfart så är man högst troligt intresserad även av äldre tiders fartyg. Alla måste hitta sitt eget historieintresse! Jag har redan nämnt släktforskning och samlandet av antikviteter är ett annat exempel på ett utbrett historieintresse.
                        <br/><br/>                        
                    </p>
                     <div class="content-paragraph borderedImage hideMobile">
                        <img  src="@/assets/skraeddaraasen.jpg" style="height: 100%;" />
                        <p>Torpet Skräddaråsen i Huddinge socken var bebott åren 1760–1817. Nu tvåhundra år senare återstår bara några mossbevuxna husgrunder.</p>
                    </div>
                </div>

                 <div class="content-paragraph">
                        <p>
                            
                            <b>Vad har du lärt dig under arbetet med bildbyrån?</b>
                            <br/><br/>
                            Oj, jag har lärt mig massor. Jag har fått inblickar i en massa historiska skeenden när jag skrivit beskrivningarna av bilderna. Att lära sig om xylografihantverket har också varit mycket givande. Det var också mycket lärorikt att skapa kategorisystemet. Jag insåg att kategorier är ett absolut nödvändigt hjälpmedel för att hitta bilder. Därför började jag med att skapa kategorier för allt mellan himmel och jord och sedan inordna dem hierarkiskt under tolv huvudkategorier. Tack vare att kategorierna finns behöver man inte veta vilket ord man ska söka efter, utan man kan söka sig fram stegvis nivå för nivå i stället.
                            <br/><br/>
                            Jag brukar säga att det är ungefär som att gå till biblioteket: vet man inte titeln på någon bok man vill läsa så tittar man på ämnesskyltarna så att man kommer till hyllan med böcker inom ens intresseområde och då kan man hitta intressanta böcker som man inte visste fanns. Jag kan nu stolt proklamera att jag har kategoriserat hela världen: naturen och all mänsklig verksamhet. Det låter väl lagom pretentiöst? Jag har också tvingats reflektera över ords definitioner på ett helt annat sätt än tidigare, för ju större en databas blir desto viktigare är det att man använder ord på ett medvetet sätt för att skapa konsekvens och därmed god sökbarhet.
                            <br/><br/>
                            <b>Vad ska du göra nu?</b>
                            <br/><br/>
                            Fortsätta scanna och beskriva bilder förstås! Bara för att bildbyrån lanseras nu på hösten 2024 betyder det inte att den är klar. Jag har tillräckligt med bildmaterial för att ha att göra i många, många år. Det här är kul och jag hoppas att många kommer att dela min glädje och mitt engagemang för detta intressanta bildmaterial!
                            <br/><br/>
                            <b>Men bildbyrån är väl inte allt du håller på med?</b>
                            <br/><br/>
                            Det stämmer. Jag har mer än tjugo års erfarenhet av bokproduktion och åtar mig uppdrag som bildredaktör, översättare och språkgranskare. Jag översätter från engelska, tyska och norska till svenska. Jag har framför allt arbetat med bokprojekt om historia, främst militärhistoria, men även böcker inom andra fackområden.
                        </p>
                    </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.illustration-info-container {
    text-align: left;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.05);

    .illustration-info {
        margin: 0 25%;
        // height: 100vh;
        background-color: #fff;

        @media only screen and (max-width: 992px) {
            margin: 0 20px;
            width: 100%;
            height: 100%;
        }

        @media only screen and (max-width: 600px) {
            width: auto;
        }

        // @media screen and (max-width: $bp-mobile) {
        //   width: 100%;
        //   margin: 0;
        // }

        .header {
            span {
                margin: 0 1em;
                cursor: pointer;
            }

            h3 {
                padding: 1em;
            }
        }

          .borderedImage{
                border: 1px solid gray;
                text-align: center;
                font-size: 14px;
                width: 45%;
                height: fit-content;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
                
            }

            .showMobile{
                    display: none;
                    @media only screen and (max-width: 600px) {
                        display: block;
                    }
                }

        .content {
            padding: 1em;

            h1 {
                font-size: 30px;
                font-weight: 500;
                line-height: 36px;
            }

            h2 {
                font-size: 22px;
                font-weight: 500;
                line-height: 36px;
            }

            .content-paragraph {
                font-size: 16px;
                font-weight: 500;
                line-height: 29px;

                .hideMobile{
                @media only screen and (max-width: 600px) {
                    display: none;
                }
                }

                .showMobile{
                    display: none;
                    @media only screen and (max-width: 600px) {
                        display: block;
                    }
                }

                .borderedImage{
                border: 1px solid gray;
                text-align: center;
                font-size: 14px;
                width: 45%;
                height: fit-content;

                @media only screen and (max-width: 600px) {
                    width: 100%;
                }
                
            }
             .borderedImage img{
                width: 100%;
             }

             .borderedImage p{
                font-size: 12px;
                line-height: 20px;
                margin-top: 10px;
             }

                ul {
                    list-style-type: disc;
                    padding-left: 20px;
                }

                a {
                    text-decoration: underline;
                    color: #1c1c68;
                }

                ol {
                    list-style-type: auto;
                    padding-left: 20px;
                }
            }

            .split-content {
                display: flex;
                flex-direction: row;
                gap: 30px;

                .leftSide{
                    width: 55%;

                     @media only screen and (max-width: 600px) {
                    width: 100%;
                }
                }

                img {
                    width: 40%;
                }

                @media only screen and (max-width: 992px) {
                    flex-direction: column;

                    img {
                        width: 100%;
                    }
                }


            }

            div {
                padding: 1em;
            }

            .about {
                p {
                    margin: 0;
                    font-size: 0.9em;
                    line-height: 1.4em;
                }
            }

            .category {
                div {
                    padding: 0;
                    margin: 1em 0;

                    &:last-of-type {
                        margin-bottom: 0;
                        border-bottom: none;
                    }
                }
            }

            .description {
                background-color: #dfe6e9;
                padding-left: 0;
                padding-right: 0;
            }

            .tags {
                display: block;

                .badge {
                    display: inline-block;
                    font-size: 0.75em;
                    padding: 0.5em 0.75em;
                    border-radius: 5px;
                    color: #fff;
                    margin: 0 0.5em 0.5em 0;
                    background-color: #1c1c68;
                }
            }
        }
    }


}</style>
